export const ItemType = {
  foreStone: 1,
  waterStone: 2,
  thunderStone: 3,
  leafStone: 4,
  moonStone: 5,
  sunStone: 6,
  shinyStone: 7,
  duskStone: 8,
  dawnStone: 9,
  iceStone: 10,
  electirizer: 11,
  magmarizer: 12,
  dragonScale: 13,
  reaperCloth: 14,
  protector: 15,
  upGrade: 16,
  dubiousDisc: 17,
  prismScale: 18,
  ovalStone: 19,
  kingsRock: 20,
  razorFang: 21,
  razorClaw: 22,
  deepSeaTooth: 23,
  deepSeaScale: 24,
  metalCoat: 25,
  linkingCord: 26,
  flamePlate: 27,
  splashPlate: 28,
  zapPlate: 29,
  meadowPlate: 30,
  iciclePlate: 31,
  fistPlate: 32,
  toxicPlate: 33,
  earthPlate: 34,
  skyPlate: 35,
  mindPlate: 36,
  insectPlate: 37,
  stonePlate: 38,
  spookyPlate: 39,
  dracoPlate: 40,
  dreadPlate: 41,
  ironPlate: 42,
  blankPlate: 43,
  pokeball: 44,
  rareCandy: 45,
};

export const SUBSTITUTE_DAILY_ITEM = ItemType.rareCandy;
