export const pokemonIds = {
  Bulbasaur: 1,
  Ivysaur: 2,
  Venusaur: 3,
  Charmander: 4,
  Charmeleon: 5,
  Charizard: 6,
  Squirtle: 7,
  Wartortle: 8,
  Blastoise: 9,
  Caterpie: 10,
  Metapod: 11,
  Butterfree: 12,
  Weedle: 13,
  Kakuna: 14,
  Beedrill: 15,
  Pidgey: 16,
  Pidgeotto: 17,
  Pidgeot: 18,
  Rattata: 19,
  Raticate: 20,
  Spearow: 21,
  Fearow: 22,
  Ekans: 23,
  Arbok: 24,
  Pikachu: 25,
  Raichu: 26,
  Sandshrew: 27,
  Sandslash: 28,
  Nidoran_female: 29,
  Nidorina: 30,
  Nidoqueen: 31,
  Nidoran_male: 32,
  Nidorino: 33,
  Nidoking: 34,
  Clefairy: 35,
  Clefable: 36,
  Vulpix: 37,
  Ninetales: 38,
  Jigglypuff: 39,
  Wigglytuff: 40,
  Zubat: 41,
  Golbat: 42,
  Oddish: 43,
  Gloom: 44,
  Vileplume: 45,
  Paras: 46,
  Parasect: 47,
  Venonat: 48,
  Venomoth: 49,
  Diglett: 50,
  Dugtrio: 51,
  Meowth: 52,
  Persian: 53,
  Psyduck: 54,
  Golduck: 55,
  Mankey: 56,
  Primeape: 57,
  Growlithe: 58,
  Arcanine: 59,
  Poliwag: 60,
  Poliwhirl: 61,
  Poliwrath: 62,
  Abra: 63,
  Kadabra: 64,
  Alakazam: 65,
  Machop: 66,
  Machoke: 67,
  Machamp: 68,
  Bellsprout: 69,
  Weepinbell: 70,
  Victreebel: 71,
  Tentacool: 72,
  Tentacruel: 73,
  Geodude: 74,
  Graveler: 75,
  Golem: 76,
  Ponyta: 77,
  Rapidash: 78,
  Slowpoke: 79,
  Slowbro: 80,
  Magnemite: 81,
  Magneton: 82,
  Farfetchd: 83,
  Doduo: 84,
  Dodrio: 85,
  Seel: 86,
  Dewgong: 87,
  Grimer: 88,
  Muk: 89,
  Shellder: 90,
  Cloyster: 91,
  Gastly: 92,
  Haunter: 93,
  Gengar: 94,
  Onix: 95,
  Drowzee: 96,
  Hypno: 97,
  Krabby: 98,
  Kingler: 99,
  Voltorb: 100,
  Electrode: 101,
  Exeggcute: 102,
  Exeggutor: 103,
  Cubone: 104,
  Marowak: 105,
  Hitmonlee: 106,
  Hitmonchan: 107,
  Lickitung: 108,
  Koffing: 109,
  Weezing: 110,
  Rhyhorn: 111,
  Rhydon: 112,
  Chansey: 113,
  Tangela: 114,
  Kangaskhan: 115,
  Horsea: 116,
  Seadra: 117,
  Goldeen: 118,
  Seaking: 119,
  Staryu: 120,
  Starmie: 121,
  MrMime: 122,
  Scyther: 123,
  Jynx: 124,
  Electabuzz: 125,
  Magmar: 126,
  Pinsir: 127,
  Tauros: 128,
  Magikarp: 129,
  Gyarados: 130,
  Lapras: 131,
  Ditto: 132,
  Eevee: 133,
  Vaporeon: 134,
  Jolteon: 135,
  Flareon: 136,
  Porygon: 137,
  Omanyte: 138,
  Omastar: 139,
  Kabuto: 140,
  Kabutops: 141,
  Aerodactyl: 142,
  Snorlax: 143,
  Articuno: 144,
  Zapdos: 145,
  Moltres: 146,
  Dratini: 147,
  Dragonair: 148,
  Dragonite: 149,
  Mewtwo: 150,
  Mew: 151,
  Chikorita: 152,
  Bayleef: 153,
  Meganium: 154,
  Cyndaquil: 155,
  Quilava: 156,
  Typhlosion: 157,
  Totodile: 158,
  Croconaw: 159,
  Feraligatr: 160,
  Sentret: 161,
  Furret: 162,
  Hoothoot: 163,
  Noctowl: 164,
  Ledyba: 165,
  Ledian: 166,
  Spinarak: 167,
  Ariados: 168,
  Crobat: 169,
  Chinchou: 170,
  Lanturn: 171,
  Pichu: 172,
  Cleffa: 173,
  Igglybuff: 174,
  Togepi: 175,
  Togetic: 176,
  Natu: 177,
  Xatu: 178,
  Mareep: 179,
  Flaaffy: 180,
  Ampharos: 181,
  Bellossom: 182,
  Marill: 183,
  Azumarill: 184,
  Sudowoodo: 185,
  Politoed: 186,
  Hoppip: 187,
  Skiploom: 188,
  Jumpluff: 189,
  Aipom: 190,
  Sunkern: 191,
  Sunflora: 192,
  Yanma: 193,
  Wooper: 194,
  Quagsire: 195,
  Espeon: 196,
  Umbreon: 197,
  Murkrow: 198,
  Slowking: 199,
  Misdreavus: 200,
  Unown: 201,
  Wobbuffet: 202,
  Girafarig: 203,
  Pineco: 204,
  Forretress: 205,
  Dunsparce: 206,
  Gligar: 207,
  Steelix: 208,
  Snubbull: 209,
  Granbull: 210,
  Qwilfish: 211,
  Scizor: 212,
  Shuckle: 213,
  Heracross: 214,
  Sneasel: 215,
  Teddiursa: 216,
  Ursaring: 217,
  Slugma: 218,
  Magcargo: 219,
  Swinub: 220,
  Piloswine: 221,
  Corsola: 222,
  Remoraid: 223,
  Octillery: 224,
  Delibird: 225,
  Mantine: 226,
  Skarmory: 227,
  Houndour: 228,
  Houndoom: 229,
  Kingdra: 230,
  Phanpy: 231,
  Donphan: 232,
  Porygon2: 233,
  Stantler: 234,
  Smeargle: 235,
  Tyrogue: 236,
  Hitmontop: 237,
  Smoochum: 238,
  Elekid: 239,
  Magby: 240,
  Miltank: 241,
  Blissey: 242,
  Raikou: 243,
  Entei: 244,
  Suicune: 245,
  Larvitar: 246,
  Pupitar: 247,
  Tyranitar: 248,
  Lugia: 249,
  HoOh: 250,
  Celebi: 251,
  Treecko: 252,
  Grovyle: 253,
  Sceptile: 254,
  Torchic: 255,
  Combusken: 256,
  Blaziken: 257,
  Mudkip: 258,
  Marshtomp: 259,
  Swampert: 260,
  Poochyena: 261,
  Mightyena: 262,
  Zigzagoon: 263,
  Linoone: 264,
  Wurmple: 265,
  Silcoon: 266,
  Beautifly: 267,
  Cascoon: 268,
  Dustox: 269,
  Lotad: 270,
  Lombre: 271,
  Ludicolo: 272,
  Seedot: 273,
  Nuzleaf: 274,
  Shiftry: 275,
  Taillow: 276,
  Swellow: 277,
  Wingull: 278,
  Pelipper: 279,
  Ralts: 280,
  Kirlia: 281,
  Gardevoir: 282,
  Surskit: 283,
  Masquerain: 284,
  Shroomish: 285,
  Breloom: 286,
  Slakoth: 287,
  Vigoroth: 288,
  Slaking: 289,
  Nincada: 290,
  Ninjask: 291,
  Shedinja: 292,
  Whismur: 293,
  Loudred: 294,
  Exploud: 295,
  Makuhita: 296,
  Hariyama: 297,
  Azurill: 298,
  Nosepass: 299,
  Skitty: 300,
  Delcatty: 301,
  Sableye: 302,
  Mawile: 303,
  Aron: 304,
  Lairon: 305,
  Aggron: 306,
  Meditite: 307,
  Medicham: 308,
  Electrike: 309,
  Manectric: 310,
  Plusle: 311,
  Minun: 312,
  Volbeat: 313,
  Illumise: 314,
  Roselia: 315,
  Gulpin: 316,
  Swalot: 317,
  Carvanha: 318,
  Sharpedo: 319,
  Wailmer: 320,
  Wailord: 321,
  Numel: 322,
  Camerupt: 323,
  Torkoal: 324,
  Spoink: 325,
  Grumpig: 326,
  Spinda: 327,
  Trapinch: 328,
  Vibrava: 329,
  Flygon: 330,
  Cacnea: 331,
  Cacturne: 332,
  Swablu: 333,
  Altaria: 334,
  Zangoose: 335,
  Seviper: 336,
  Lunatone: 337,
  Solrock: 338,
  Barboach: 339,
  Whiscash: 340,
  Corphish: 341,
  Crawdaunt: 342,
  Baltoy: 343,
  Claydol: 344,
  Lileep: 345,
  Cradily: 346,
  Anorith: 347,
  Armaldo: 348,
  Feebas: 349,
  Milotic: 350,
  Castform: 351,
  Kecleon: 352,
  Shuppet: 353,
  Banette: 354,
  Duskull: 355,
  Dusclops: 356,
  Tropius: 357,
  Chimecho: 358,
  Absol: 359,
  Wynaut: 360,
  Snorunt: 361,
  Glalie: 362,
  Spheal: 363,
  Sealeo: 364,
  Walrein: 365,
  Clamperl: 366,
  Huntail: 367,
  Gorebyss: 368,
  Relicanth: 369,
  Luvdisc: 370,
  Bagon: 371,
  Shelgon: 372,
  Salamence: 373,
  Beldum: 374,
  Metang: 375,
  Metagross: 376,
  Regirock: 377,
  Regice: 378,
  Registeel: 379,
  Latias: 380,
  Latios: 381,
  Kyogre: 382,
  Groudon: 383,
  Rayquaza: 384,
  Jirachi: 385,
  Deoxys: 386,
  Turtwig: 387,
  Grotle: 388,
  Torterra: 389,
  Chimchar: 390,
  Monferno: 391,
  Infernape: 392,
  Piplup: 393,
  Prinplup: 394,
  Empoleon: 395,
  Starly: 396,
  Staravia: 397,
  Staraptor: 398,
  Bidoof: 399,
  Bibarel: 400,
  Kricketot: 401,
  Kricketune: 402,
  Shinx: 403,
  Luxio: 404,
  Luxray: 405,
  Budew: 406,
  Roserade: 407,
  Cranidos: 408,
  Rampardos: 409,
  Shieldon: 410,
  Bastiodon: 411,
  Burmy: 412,
  Wormadam: 413,
  Mothim: 414,
  Combee: 415,
  Vespiquen: 416,
  Pachirisu: 417,
  Buizel: 418,
  Floatzel: 419,
  Cherubi: 420,
  Cherrim: 421,
  Shellos: 422,
  Gastrodon: 423,
  Ambipom: 424,
  Drifloon: 425,
  Drifblim: 426,
  Buneary: 427,
  Lopunny: 428,
  Mismagius: 429,
  Honchkrow: 430,
  Glameow: 431,
  Purugly: 432,
  Chingling: 433,
  Stunky: 434,
  Skuntank: 435,
  Bronzor: 436,
  Bronzong: 437,
  Bonsly: 438,
  MimeJr: 439,
  Happiny: 440,
  Chatot: 441,
  Spiritomb: 442,
  Gible: 443,
  Gabite: 444,
  Garchomp: 445,
  Munchlax: 446,
  Riolu: 447,
  Lucario: 448,
  Hippopotas: 449,
  Hippowdon: 450,
  Skorupi: 451,
  Drapion: 452,
  Croagunk: 453,
  Toxicroak: 454,
  Carnivine: 455,
  Finneon: 456,
  Lumineon: 457,
  Mantyke: 458,
  Snover: 459,
  Abomasnow: 460,
  Weavile: 461,
  Magnezone: 462,
  Lickilicky: 463,
  Rhyperior: 464,
  Tangrowth: 465,
  Electivire: 466,
  Magmortar: 467,
  Togekiss: 468,
  Yanmega: 469,
  Leafeon: 470,
  Glaceon: 471,
  Gliscor: 472,
  Mamoswine: 473,
  PorygonZ: 474,
  Gallade: 475,
  Probopass: 476,
  Dusknoir: 477,
  Froslass: 478,
  Rotom: 479,
  Uxie: 480,
  Mesprit: 481,
  Azelf: 482,
  Dialga: 483,
  Palkia: 484,
  Heatran: 485,
  Regigigas: 486,
  Giratina: 487,
  Cresselia: 488,
  Phione: 489,
  Manaphy: 490,
  Darkrai: 491,
  Shaymin: 492,
  Arceus: 493,
} as const;

export const pokemonForms: Record<number, null | string[]> = {
  201: [
    "-exclamation",
    "-question",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ],
  412: ["plant", "sandy", "trash"],
  413: ["plant", "sandy", "trash"],
  422: ["west", "east"],
  423: ["west", "east"],
  479: [null, "heat", "wash", "frost", "fan", "mow"],
  487: ["altered", "origin"],
  492: ["land", "sky"],
  493: [
    null,
    "bug",
    "dark",
    "dragon",
    "electric",
    "fighting",
    "fire",
    "flying",
    "ghost",
    "grass",
    "ground",
    "ice",
    "poison",
    "psychic",
    "rock",
    "steel",
    "water",
  ],
};

export const pokemonBackgroundColors: Record<number, string> = {
  1: "#82daa4",
  2: "#7bae9c",
  3: "#a8c081",
  4: "#ffa34e",
  5: "#ffe441",
  6: "#ea9b5d",
  7: "#add1ab",
  8: "#f2eaed",
  9: "#d4b7b7",
  10: "#9bc562",
  11: "#ff772f",
  12: "#d5c2d4",
  13: "#f79b4a",
  14: "#9bea27",
  15: "#fcddcb",
  16: "#ded541",
  17: "#f9e76a",
  18: "#ffe54c",
  19: "#cf8dad",
  20: "#edb35a",
  21: "#d77a53",
  22: "#e0d037",
  23: "#cbbf3d",
  24: "#9d79bf",
  25: "#fff144",
  26: "#ffc562",
  27: "#bbde87",
  28: "#ddba4f",
  29: "#d1ceff",
  30: "#ace8fd",
  31: "#92c1c7",
  32: "#d492e0",
  33: "#b0adfd",
  34: "#db97dc",
  35: "#ffdabb",
  36: "#fffffc",
  37: "#fd874a",
  38: "#ffff81",
  39: "#fff1ff",
  40: "#fff6ff",
  41: "#a3ba51",
  42: "#d1a470",
  43: "#9be927",
  44: "#c3b142",
  45: "#ff8186",
  46: "#f5713f",
  47: "#ff664b",
  48: "#b783a9",
  49: "#ffe8ff",
  50: "#e19e6b",
  51: "#ec9c69",
  52: "#f5c659",
  53: "#ffff99",
  54: "#ffec7b",
  55: "#a6b4d6",
  56: "#d9dc84",
  57: "#f6c29a",
  58: "#fff680",
  59: "#ffea92",
  60: "#c4cbeb",
  61: "#b7bfde",
  62: "#cbe6b6",
  63: "#ffe42f",
  64: "#fbda30",
  65: "#f5d939",
  66: "#a8d5ca",
  67: "#c59bde",
  68: "#9cc140",
  69: "#efcd4a",
  70: "#f4ff6d",
  71: "#d5d476",
  72: "#a9bbbf",
  73: "#9f9f98",
  74: "#ffcc0e",
  75: "#cc7904",
  76: "#b3ab59",
  77: "#e9ffff",
  78: "#fff5dc",
  79: "#ffadb9",
  80: "#ffb5a9",
  81: "#aab7ad",
  82: "#b0b7b0",
  83: "#ffd29d",
  84: "#c79d6c",
  85: "#b3af50",
  86: "#ffffff",
  87: "#ffffff",
  88: "#c4ff9e",
  89: "#9fdc7a",
  90: "#ff9924",
  91: "#7ab2ff",
  92: "#f2c0e4",
  93: "#cb92e2",
  94: "#a8a4d0",
  95: "#d5e464",
  96: "#ffabc3",
  97: "#ffb4d1",
  98: "#ff986f",
  99: "#bdc869",
  100: "#ff9d7e",
  101: "#ffffff",
  102: "#ffd3e6",
  103: "#ffb846",
  104: "#fde7cf",
  105: "#fadcbb",
  106: "#e6bb94",
  107: "#ddaa91",
  108: "#ffbfc8",
  109: "#e4ddfd",
  110: "#babfd6",
  111: "#dd8967",
  112: "#e9daac",
  113: "#ffffd4",
  114: "#6fa4c6",
  115: "#f0deb4",
  116: "#72dbcf",
  117: "#a796e6",
  118: "#fffff1",
  119: "#fffec4",
  120: "#ecb15e",
  121: "#8c8bcc",
  122: "#bed9b7",
  123: "#c9f4a3",
  124: "#ffb6dc",
  125: "#e3cc4c",
  126: "#ffa9ba",
  127: "#b9a3cb",
  128: "#c9e451",
  129: "#fffa7c",
  130: "#97d5d2",
  131: "#eeb7f7",
  132: "#fb97ff",
  133: "#eeae6f",
  134: "#f099d1",
  135: "#ffe976",
  136: "#ffb07f",
  137: "#9caecd",
  138: "#e2b2a4",
  139: "#abc4bb",
  140: "#85b822",
  141: "#bae173",
  142: "#d7a9ff",
  143: "#e7fae5",
  144: "#b1edff",
  145: "#ffc10f",
  146: "#ff8e3d",
  147: "#ffb3f6",
  148: "#ffcdff",
  149: "#ffe180",
  150: "#e0beff",
  151: "#a1eaff",
  152: "#e5ff7b",
  153: "#e9ea3e",
  154: "#fff63d",
  155: "#ffaa5a",
  156: "#ffb75b",
  157: "#ffd790",
  158: "#7febba",
  159: "#91f3c2",
  160: "#8fdadd",
  161: "#d9aa80",
  162: "#ffd59b",
  163: "#eec45d",
  164: "#e1b47d",
  165: "#e4af87",
  166: "#dcb669",
  167: "#a1ca58",
  168: "#ce7f64",
  169: "#f1c7ac",
  170: "#7ddec2",
  171: "#e6b1cb",
  172: "#d7be57",
  173: "#fff0f0",
  174: "#ffbaf0",
  175: "#ffe4d5",
  176: "#ffffed",
  177: "#d3d143",
  178: "#cad291",
  179: "#fffdb4",
  180: "#fff1ff",
  181: "#fff37e",
  182: "#bfb3a0",
  183: "#94bfff",
  184: "#8fc4ff",
  185: "#e7d754",
  186: "#c7d636",
  187: "#a3f63b",
  188: "#ffa9bb",
  189: "#ffffff",
  190: "#e4a9c6",
  191: "#e8d130",
  192: "#fff771",
  193: "#ffead3",
  194: "#ffd6d5",
  195: "#ff9eff",
  196: "#81ee42",
  197: "#5d6573",
  198: "#51607d",
  199: "#ffbea8",
  200: "#7c82ae",
  201: "#919191",
  202: "#fd7bcf",
  203: "#f4d170",
  204: "#549eb0",
  205: "#c182bc",
  206: "#fff4af",
  207: "#cc85ca",
  208: "#f2da77",
  209: "#ddc5ef",
  210: "#e9bcaf",
  211: "#89c1aa",
  212: "#d75e62",
  213: "#b5bcac",
  214: "#ef82f3",
  215: "#fda2b0",
  216: "#c7ffa9",
  217: "#ca834b",
  218: "#ff7832",
  219: "#f18a53",
  220: "#d29d75",
  221: "#e4b388",
  222: "#a1fbff",
  223: "#ffbcff",
  224: "#e9603e",
  225: "#ffc1b0",
  226: "#b5cfff",
  227: "#cdd794",
  228: "#9ab3e8",
  229: "#756d71",
  230: "#a4d0fc",
  231: "#f0ffff",
  232: "#ecc16f",
  233: "#bb9dca",
  234: "#e4ff43",
  235: "#e4d7a2",
  236: "#f3b2c9",
  237: "#d3c6b3",
  238: "#fff5a7",
  239: "#f3c03e",
  240: "#ffde37",
  241: "#c4bcc7",
  242: "#ffffff",
  243: "#e7c395",
  244: "#f8bc9f",
  245: "#a4d4ff",
  246: "#b9d397",
  247: "#d298ff",
  248: "#f9e1a9",
  249: "#f4ffff",
  250: "#ffa761",
  251: "#ffd6e6",
  252: "#a1c66e",
  253: "#80af8f",
  254: "#9bd5c3",
  255: "#ffbf64",
  256: "#fbb66b",
  257: "#ffb17a",
  258: "#ffc9f7",
  259: "#fba0d4",
  260: "#9dc2ed",
  261: "#b7b0c3",
  262: "#747285",
  263: "#f3a56e",
  264: "#ffe7c9",
  265: "#ffcda3",
  266: "#ffffa2",
  267: "#c9ba85",
  268: "#c2ff78",
  269: "#c8c588",
  270: "#98cfcd",
  271: "#89ba9f",
  272: "#e2f1bb",
  273: "#ffc490",
  274: "#e7cfb1",
  275: "#e8dcc2",
  276: "#99d0a0",
  277: "#7a7399",
  278: "#fbfdff",
  279: "#fffab3",
  280: "#e3f4d2",
  281: "#cdf8ba",
  282: "#f3ffff",
  283: "#8bbeba",
  284: "#fab8c5",
  285: "#fedd91",
  286: "#c8b358",
  287: "#e79cda",
  288: "#fff1ad",
  289: "#e2c0a2",
  290: "#e6eac9",
  291: "#ffe5a3",
  292: "#ffecbf",
  293: "#ffc6e3",
  294: "#d48aa7",
  295: "#dea09c",
  296: "#ffde7f",
  297: "#e39aa1",
  298: "#6eaeff",
  299: "#838bca",
  300: "#ffb196",
  301: "#ff8c52",
  302: "#9a8ae2",
  303: "#bca483",
  304: "#dfdecc",
  305: "#d6c8c9",
  306: "#b7b0ae",
  307: "#ffbcd3",
  308: "#c2bec9",
  309: "#90d8ef",
  310: "#ceb378",
  311: "#ffbd77",
  312: "#f1ffa7",
  313: "#d6ada6",
  314: "#c7cdad",
  315: "#b1b376",
  316: "#ddffab",
  317: "#e6adff",
  318: "#bbd87e",
  319: "#98c1df",
  320: "#ffe1ff",
  321: "#c2e1ff",
  322: "#fff2aa",
  323: "#ffa367",
  324: "#ffe2d5",
  325: "#f6cebb",
  326: "#dab98e",
  327: "#fce59a",
  328: "#ffc485",
  329: "#ecb785",
  330: "#dcfe99",
  331: "#89c94a",
  332: "#98e876",
  333: "#fffffa",
  334: "#ffffff",
  335: "#f2ffff",
  336: "#917981",
  337: "#ffee7a",
  338: "#ffc635",
  339: "#e8c85e",
  340: "#9b8e93",
  341: "#ffa958",
  342: "#ffb35a",
  343: "#f2f95e",
  344: "#d5b59b",
  345: "#f1d475",
  346: "#ffb0a3",
  347: "#caa599",
  348: "#a999a4",
  349: "#cecbbc",
  350: "#ebc2b7",
  351: "#ffffff",
  352: "#bdf49a",
  353: "#50b4c2",
  354: "#ab8f9f",
  355: "#a8a28a",
  356: "#e37a6f",
  357: "#8ac56c",
  358: "#e2cee6",
  359: "#ffcce6",
  360: "#87cdf2",
  361: "#ddaf88",
  362: "#c9d1d8",
  363: "#d7d8f0",
  364: "#fff7ff",
  365: "#b2e3ff",
  366: "#8f9dd4",
  367: "#c2d0b0",
  368: "#ffec77",
  369: "#c9edcd",
  370: "#ffff24",
  371: "#b1ffa4",
  372: "#d0ffbf",
  373: "#b6c8cc",
  374: "#b6a7b1",
  375: "#e8ccbf",
  376: "#7aacfd",
  377: "#f19558",
  378: "#dde6ff",
  379: "#e8ddd7",
  380: "#ff9eb7",
  381: "#a8bdfd",
  382: "#8895e9",
  383: "#b9bb76",
  384: "#8fbc8c",
  385: "#ffe583",
  386: "#ce8c81",
  387: "#d0e377",
  388: "#b9f19c",
  389: "#b1da8b",
  390: "#ffa484",
  391: "#ffa69e",
  392: "#fdb082",
  393: "#a3fffd",
  394: "#92e5d4",
  395: "#71cdcc",
  396: "#bca8a1",
  397: "#978484",
  398: "#cfa68c",
  399: "#ffcd76",
  400: "#d0a26e",
  401: "#daa174",
  402: "#e69e43",
  403: "#f4c35f",
  404: "#7e87a3",
  405: "#696b81",
  406: "#afe66b",
  407: "#dfeacd",
  408: "#d4a495",
  409: "#90a6bc",
  410: "#b3a294",
  411: "#7e8ca5",
  412: "#6f8c66",
  413: "#c0a484",
  414: "#bfe2a3",
  415: "#ff9e70",
  416: "#c5a348",
  417: "#ffffff",
  418: "#fbea74",
  419: "#ffd56a",
  420: "#ffbc6c",
  421: "#ffccd5",
  422: "#aed2cd",
  423: "#e8a476",
  424: "#fb8aa7",
  425: "#ffffac",
  426: "#e8ff8b",
  427: "#f6adaf",
  428: "#f5c995",
  429: "#8c5ca4",
  430: "#777297",
  431: "#aba9c5",
  432: "#c5c5e5",
  433: "#fdc276",
  434: "#a489aa",
  435: "#b79bb3",
  436: "#5cae54",
  437: "#53b1cf",
  438: "#a6b864",
  439: "#c2cbc6",
  440: "#ffe6ff",
  441: "#94849d",
  442: "#b2d8ff",
  443: "#81a4b8",
  444: "#7f6ad2",
  445: "#8874ac",
  446: "#85b5ea",
  447: "#547fa0",
  448: "#618598",
  449: "#d0c469",
  450: "#cabca7",
  451: "#8390d1",
  452: "#9c70ad",
  453: "#8eb4c6",
  454: "#8ea6bb",
  455: "#a3a167",
  456: "#d19f8b",
  457: "#bda47e",
  458: "#67cfff",
  459: "#c8d6dc",
  460: "#d2edff",
  461: "#ffc2a1",
  462: "#bdc3df",
  463: "#ffa6b7",
  464: "#aa7269",
  465: "#5cb23e",
  466: "#c8ac48",
  467: "#ff838f",
  468: "#ffffff",
  469: "#bb7ab5",
  470: "#99e58e",
  471: "#97daff",
  472: "#6db0da",
  473: "#dcf298",
  474: "#ccb9fd",
  475: "#bfc6ff",
  476: "#b37aa7",
  477: "#7c928f",
  478: "#e9efff",
  479: "#ffa89f",
  480: "#ffcb78",
  481: "#e0a9da",
  482: "#a2b4de",
  483: "#8cb7e0",
  484: "#fbe3ff",
  485: "#dcb2a6",
  486: "#dedcba",
  487: "#778274",
  488: "#bcd1cd",
  489: "#6bf6f9",
  490: "#80fef4",
  491: "#6d468f",
  492: "#f3fffd",
  493: "#dadbe2",
};
